import React, { useState, useEffect } from "react";
import { Input, Modal } from "antd";
import {
  FileTextOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import download from "downloadjs";
import { Spin } from "antd";

import { Button, Table } from "../../components";

import { getUrl, requests } from "../../globals/requests";
import ViewLoader from "../../globals/components/Loading/ViewLoader";

import SortingBtn from "../../components/sortingBtn/SortingBtn";
import _ from "lodash";
import {
  Container,
  FlexEnd,
  SpaceBetween,
  TableContainer,
  TableButtonsContainer,
} from "./styles";
import {
  getFlattenedOrganisations,
  setOrganisationID,
  setOrganisationsAfterSort,
} from "../../state/actions/organisationActions";
import { helpers } from "../../utils";

import "./organisations.css";
import { useHistory } from "react-router-dom";

const routes = [
  {
    path: "index",
    breadcrumbName: "First-level Menu",
  },
  {
    path: "first",
    breadcrumbName: "Second-level Menu",
  },
  {
    path: "second",
    breadcrumbName: "Third-level Menu",
  },
];

function Organisations(props) {
  const {
    getFlattenedOrganisations,
    organisations,
    loadingGetOrgs,
    setOrganisationID,
    sortOrganisationType,
    setOrganisationsAfterSort,
  } = props;
  const [orgs, setOrgs] = useState(props.organisations);
  const [buff, setBuff] = useState({});
  const [loadingExport, setLoadingExport] = useState(false);
  // const [loadingGetOrgCode, setLoadingGetOrgCode] = useState(false);
  const [loadingGetOrgCode, setLoadingGetOrgCode] = useState(false);

  const history = useHistory();
  const handleActiveSortOtion =
    sortOrganisationType?.length &&
    sortOrganisationType.filter((item) => item.active);

  const handleSortOrganisation = (
    data,
    filterSortType,
    setOrganisationsAfterSort
  ) => {
    let afterSort;
    switch (filterSortType.item) {
      case "Name A-Z":
        afterSort = data.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          return nameA < nameB ? -1 : 1;
        });
        afterSort = afterSort.map((item) => ({
          ...item,
          dataChangeBySort: Date.now(),
        }));
        return setOrganisationsAfterSort(afterSort);

      case "Name Z-A":
        afterSort = data.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          return nameB > nameA ? 1 : -1;
        });
        afterSort = afterSort.map((item) => ({
          ...item,
          dataChangeBySort: Date.now(),
        }));
        return setOrganisationsAfterSort(afterSort);

      case "End Date Paid":
        afterSort = data.sort((a, b) => {
          const aEnd = a?.latestPaidContractEndDateNumber;
          const bEnd = b?.latestPaidContractEndDateNumber;
          return bEnd - aEnd;
        });
        afterSort = afterSort.map((item) => ({
          ...item,
          dataChangeBySort: Date.now(),
        }));
        return setOrganisationsAfterSort(afterSort);

      case "End Date Ever":
        afterSort = data.sort((a, b) => {
          const aEnd = a?.latestEverContractEndDateNumber;
          const bEnd = b?.latestEverContractEndDateNumber;
          return bEnd - aEnd;
        });
        afterSort = afterSort.map((item) => ({
          ...item,
          dataChangeBySort: Date.now(),
        }));
        return setOrganisationsAfterSort(afterSort);

      default:
        return data;
    }
  };

  useEffect(async () => {
    async function getOrgs() {
      try {
        const dOrgs = await getFlattenedOrganisations();
        setOrgs(dOrgs);
        return dOrgs;
      } catch (e) {
        helpers.displayMessage(e.message);
      }
    }

    const data = await getOrgs();

    handleSortOrganisation(
      data,
      handleActiveSortOtion[0],
      setOrganisationsAfterSort
    );

    return () => {
      data = [];
      setOrgs([]);
    };
  }, []);

  useEffect(() => {
    handleSortOrganisation(
      props.organisations,
      handleActiveSortOtion[0],
      setOrganisationsAfterSort
    );
    setOrgs(props.organisations);
  }, [handleActiveSortOtion[0].item, organisations?.length]);

  function viewOrgContracts(org) {
    history.push(`/dashboard/orgContracts/${org._id}`);
  }

  const addContractHandler = (org) => {
    setOrganisationID(org._id);
    history.push(`/dashboard/contracts`);
  };

  async function getOrgCode(id) {
    try {
      const url = `${getUrl("organisations")}/${id}`;
      const orgCode = helpers.generateRandomNumbers(11);

      setLoadingGetOrgCode(true);
      const updatedOrg = await requests.putWithAuth(url, { orgCode });
      setLoadingGetOrgCode(false);
      const foundOrgIndex = orgs.findIndex((el) => el._id === id);
      if (foundOrgIndex !== -1) {
        const cOfOrgs = JSON.parse(JSON.stringify(orgs));
        cOfOrgs[foundOrgIndex].orgCode = updatedOrg.orgCode;
        setOrgs(cOfOrgs);
        successModal(`Operation succesful, org code is: ${orgCode}`);
      }
    } catch (e) {
      console.log(e);
      setLoadingGetOrgCode(false);
      helpers.displayMessage(e.message);
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Contract Amount",
      dataIndex: "latestContract.amount",
      key: "contractAmount",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      // responsive: ["xl"],
    },
    {
      title: "Organisation code",
      dataIndex: "orgCode",
      key: "orgCode",
    },
    {
      title: "No of Trips",
      dataIndex: "noOfTrips",
      key: "noOfTrips",
      // responsive: ["xxl"],
    },
    {
      title: "No of Teachers",
      dataIndex: "noOfStaff",
      key: "noOfStaff",
      // responsive: ["xxl"],
    },
    {
      title: "No of Students",
      dataIndex: "noOfStudents",
      key: "noOfStudents",
      // responsive: ["xxl"],
    },
    {
      // title: "Latest Paid",
      title: "End Date Paid",
      dataIndex: "",
      key: "contractEndDate",
      render: (org) =>
        org?.latestContract?.endDate ? (
          <p>{org?.latestContract?.endDate.slice(0, 10)}</p>
        ) : null,
    },
    {
      // title: "Latest Ever",
      title: "End Date Ever",
      dataIndex: "",
      key: "latestEverContract",
      render: (org) =>
        org?.latestEverContract?.endDate ? (
          <p>{org?.latestEverContract?.endDate.slice(0, 10)}</p>
        ) : null,
    },
    {
      title: "Contract Status",
      dataIndex: "",
      key: "latestContract",
      render: (org) => {
        const status =
          org.latestPaidContractStatus || org.latestEverContractStatus;

        const styleObj = {};
        switch (status) {
          case "Current":
            styleObj.color = "green";
            break;
          case "Past":
            styleObj.color = "red";
            break;
          case "Future":
            styleObj.color = "blue";
            break;
          default:
            styleObj.color = "black";
        }
        return <p style={styleObj}>{status}</p>;
      },
    },
    {
      title: "Contract",
      dataIndex: "",
      key: "x",
      render: (org) =>
        org.latestEverContractId || org.latestContractId ? (
          <button onClick={() => viewOrgContracts(org)}>View Contracts</button>
        ) : (
          <p>n/a</p>
        ),
    },
    {
      title: "Set Org Code",
      dataIndex: "",
      key: "y",
      render: (org) =>
        org.orgCode ? (
          <p>n/a</p>
        ) : (
          <Button
            disabled={loadingGetOrgCode}
            onClick={() => getOrgCode(org._id)}
            icon={<FileTextOutlined />}
            containermargin="0 10px"
            title="Set Code"
            type="primary"
          />
        ),
    },
    {
      title: "Add Contract",
      dataIndex: "",
      key: "addContract",
      render: (org) => (
        <Button
          whilehover="true"
          shape="round"
          onClick={() => addContractHandler(org)}
          icon={<PlusOutlined />}
          containermargin="0 10px"
          style={{
            background: "var(--green)",
            border: "none",
            color: "white",
          }}
          title="Add"
          type="primary"
        />
      ),
    },
  ];

  function filterOrgs(e) {
    const searchValue = e.target.value;

    if (orgs.length === 0) {
      return;
    }

    if (searchValue === "") {
      setOrgs(props.organisations);
      return;
    }

    if (buff[searchValue]) {
      setOrgs(buff[searchValue]);
    } else {
      const filteredOrgs = orgs.filter((el) =>
        el.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setOrgs(filteredOrgs);
      setBuff({ ...buff, [searchValue]: filteredOrgs });
    }
  }

  function successModal(message) {
    Modal.success({
      content: message || "Succesful",
    });
  }

  async function downloadCsv() {
    try {
      const url = `${getUrl("organisations")}/queryAll/sheet`;
      setLoadingExport(true);
      const response = await requests.getBlobWithAuth(url, {
        status: "premium",
      });
      setLoadingExport(false);

      download(response.data, "org.csv", "text/csv");
    } catch (e) {
      setLoadingExport(false);

      helpers.displayMessage(e.message);
    }
  }

  if (loadingGetOrgs && props.organisations.length === 0) {
    return (
      <div className="spin-contain">
        <Spin delay={100} size="large" tip="Loading Organisations...">
          {/* <Alert
      message="Alert message title"
      description="Further details about the context of this alert."
      type="info"
    /> */}
        </Spin>
      </div>
    );
  }

  return (
    <Container>
      <ViewLoader show={loadingGetOrgCode} loadingText="Generting org code" />
      <TableButtonsContainer>
        <SpaceBetween>
          <div className="org-inpup-container">
            <Input
              size="large"
              onChange={filterOrgs}
              placeholder="Name"
              prefix={<SearchOutlined />}
            />
          </div>
          <SortingBtn />

          <Button
            shape="round"
            loading={loadingExport}
            onClick={downloadCsv}
            icon={<FileTextOutlined />}
            containermargin="0 10px"
            title="Export CSV"
            type="primary"
          />
        </SpaceBetween>
      </TableButtonsContainer>

      <TableContainer>
        <Table columns={columns} dataSource={_.cloneDeep(orgs)} />
      </TableContainer>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    organisations: state.organisationsReducer.organisations,
    loadingGetOrgs: state.organisationsReducer.loadingGetOrgs,
    sortOrganisationType: state.organisationsReducer.sortOrganisationType,
  };
};

export default connect(mapStateToProps, {
  getFlattenedOrganisations,
  setOrganisationID,
  setOrganisationsAfterSort,
})(Organisations);

// organisations
