import {
  // MailOutlined,
  // AppstoreOutlined,
  // SettingOutlined,
  DashboardOutlined,
  ReconciliationOutlined,
  ContainerOutlined,
  HddOutlined,
} from "@ant-design/icons";
export const sideBarMenuItemsList = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardOutlined,
    protected: true,
    subItems: [],
  },
  {
    path: "/dashboard/contracts",
    name: "Contract Management",
    icon: ReconciliationOutlined,
    protected: true,
    subItems: [],
  },
  // {
  //   path: "/dashboard/mycontract",
  //   name: "MY Contract",
  //   icon: ContainerOutlined,
  //   protected: true,
  //   subItems: [],
  // },
  {
    path: "/dashboard/organisations",
    name: "Organisations",
    icon: ContainerOutlined,
    protected: true,
    subItems: [],
  },
  {
    path: "/dashboard/backups",
    name: "Backups",
    icon: HddOutlined,
    protected: true,
    subItems: [],
  },

  //,

  // { redirect: true, path: "/", to: "/dashboard", name: "Dashboard" }
];
