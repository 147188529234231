import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { UndoOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Button, Table } from "../../components";
import { getUrl, requests } from "../../globals/requests";
import { motion } from "framer-motion/dist/framer-motion";

import _ from "lodash";
import { Container, TableContainer } from "./styles";

import "./Backups.style.css";
import { helpers } from "../../utils";

const Backups = (props) => {
  const [data, setData] = useState([]);
  // const [dataRestore, setDataRestore] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingRestore, setLoadingRestore] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errMsgRestore, setErrMsgRestore] = useState("");
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState([
    "You are trying to restore database, please confirm it",
  ]);
  const [backup, setBackup] = useState("");
  const [restoreMsg, setRestoreMmsg] = useState([]);

  const showModal = (backup) => {
    setOpen(true);
    setBackup(backup);
  };

  const handleOk = async () => {
    setModalText(["This process takes time, please be patient"]);
    setConfirmLoading(true);
    const response = await restore(backup);
    console.log(response, "response");

    if (response?.status === 200) {
      setLoadingRestore(false);
      const msgToDsp = [];
      response?.data?.forEach((item) => {
        msgToDsp.push(`${item?.collection} - succes: ${item?.importProcess}`);
      });

      setModalText([
        "This information will close for about 5s",
        "Restore results:",
      ]);
      setRestoreMmsg(response?.data);
      setTimeout(() => {
        setRestoreMmsg([]);
        setOpen(false);
        setConfirmLoading(false);
        setBackup("");
        setModalText(["You are trying to restore database, please confirm it"]);
        setErrMsgRestore("");
        setErrMsg("");
      }, 5000);
    }
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
    setBackup("");
    setErrMsgRestore("");
    setErrMsg("");
    setRestoreMmsg([]);
    setModalText(["You are trying to restore database, please confirm it"]);
  };

  async function restore(backup) {
    try {
      const data = { backupLocation: backup };
      setLoadingRestore(true);
      setErrMsgRestore("");
      const url = `${getUrl("users")}/superAdmin/restoreDB`;
      const response = await requests.postWithAuth(url, data);
      return response;
    } catch (e) {
      helpers.displayMessage(e);
      setErrMsgRestore(e.message);
      setLoadingRestore(false);
      setOpen(false);
      setConfirmLoading(false);
    }
  }

  useEffect(() => {
    async function getBackups() {
      try {
        setLoading(true);
        setErrMsg("");
        const url = `${getUrl("users")}/superAdmin/backups`;
        const response = await requests.getWithAuth(url);

        setData(response.data);
        setLoading(false);
      } catch (e) {
        helpers.displayMessage(e);
        setErrMsg(e.message);
        setLoading(false);
      }
    }

    getBackups();
  }, []);

  useEffect(() => {
    return () => {
      setData([]);
      setBackup("");
      // setDataRestore(null);
      setErrMsgRestore("");
      setErrMsg("");
      setRestoreMmsg([]);
    };
  }, []);

  const columns = [
    {
      title: "Nr",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Backup Date",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "name",
      key: "backup",
      render: (backup) => (
        <Button
          whilehover="true"
          shape="round"
          onClick={() => {
            showModal(backup);
          }}
          icon={<UndoOutlined />}
          containermargin="0 10px"
          style={{
            background: "var(--blue)",
            border: "none",
            color: "white",
          }}
          title="Restore"
          type="primary"
        />
      ),
    },
  ];

  function successModal(message) {
    Modal.success({
      content: message || "Succesful",
    });
  }

  if (loading) {
    return (
      <div className="spin-contain">
        <Spin delay={100} size="large" tip="Loading Organisations..."></Spin>
      </div>
    );
  }

  return (
    <Container>
      {errMsg && <p style={{ color: "red" }}>{errMsg}</p>}
      {errMsgRestore && <p style={{ color: "red" }}>{errMsgRestore}</p>}
      {!data?.length && !loading && <p>Backups dosen't exist</p>}
      <Modal
        title="DB Restore confirmation"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        {modalText.map((txt) => (
          <p>{txt}</p>
        ))}
        {restoreMsg?.map((msg) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0px",
                  marginRight: "5px",
                }}
              >
                {msg?.collection}
              </p>
              {msg?.importProcess === true ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#01A79B"
                  width="20px"
                >
                  <motion.path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 0.5 }}
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="red"
                  // viewBox="0 0 24 24"
                  viewBox="0 0 16 16"
                  // strokeWidth={0}
                  // stroke="red"
                  width="20px"
                >
                  <motion.path
                    // strokeLinecap="round"
                    // strokeLinejoin="round"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ duration: 0.5 }}
                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                    fill="red"
                  />
                </svg>
              )}
            </div>
          );
        })}
        {/* <p>{modalText}</p> */}
      </Modal>
      {data?.length && (
        <TableContainer>
          <Table columns={columns} dataSource={data} />
        </TableContainer>
      )}
    </Container>
  );
};

export default Backups;
