import React, { useEffect } from "react";
import CountUp from "react-countup";
import { Box, BoxInner } from "../styles";
import ImagIcon from "./ImageIcon";

function Block(props) {
  const { value, display } = props;

  // console.log(summary);
  useEffect(() => {}, [value]);

  return (
    <Box>
      <BoxInner>
        <ImagIcon display={display} />
        <CountUp
          className="ovv-countup-span"
          suffix={` ${display}`}
          end={value}
          duration={5}
          // redraw
        />
      </BoxInner>
    </Box>
  );
}

export default Block;
