import React, { useState, useEffect } from "react";
import { Modal } from "antd";

import { connect } from "react-redux";
import { clearOrganisationID } from "../../state/actions/organisationActions";

import SearchOrg from "./components/SearchOrg/SearchOrg";
import CreateContractForm from "./components/CreateContractForm/Form";
import { getUrl, requests } from "../../globals/requests";
import { helpers } from "../../utils";
import { convertToEndOfDayISO } from "../../utils/date";

function Contracts({ clearOrganisationID, organisationID }) {
  const [organisation, setOrganisation] = useState({});
  const [organisationDataByID, setOrganisationDataByID] = useState({});
  const [orgCode, setOrgCode] = useState("");
  const [loadingOrgCode, setLoadingOrgCode] = useState(false);
  const [loadingOrgByID, setLoadingOrgByID] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [searchBy, setSearchBy] = useState("Code");
  const [formValues, setFormValues] = useState({
    amount: "",
    description: "",
    contactEmail: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    //  requesting/cleaning for org data after organisationID exists or change
    if (organisationID) {
      getOrgByQueryID(organisationID);
    }
    return () => {
      clearOrganisationID();
      if (organisationDataByID._id) setOrganisationDataByID({});
      if (organisation._id) setOrganisation({});
      setFormValues({
        amount: "",
        description: "",
        contactEmail: "",
        startDate: "",
        endDate: "",
      });
    };
  }, [organisationID]);

  function handleChange(value) {
    setSearchBy(value);
    if (organisationID) {
      clearOrganisationID();
      setOrganisationDataByID({});
    }
  }

  function handleSearchInputChange(e) {
    setOrgCode(e.target.value);
    if (organisationID) {
      clearOrganisationID();
      setOrganisationDataByID({});
    }
  }

  function handleInputChange(e) {
    const { name, value } = e.target;

    setFormValues({ ...formValues, [name]: value });
  }

  function handleStartDateChange(date, dateString) {
    // const dateString = date.format("MMMM Do YYYY, h:mm:ss a");
    setFormValues({ ...formValues, startDate: dateString });
  }

  function handleEndDateChange(date, dateString) {
    const correctEndOfday = convertToEndOfDayISO(dateString);
    // const dateString = date.format("MMMM Do YYYY, h:mm:ss a");
    setFormValues({ ...formValues, endDate: correctEndOfday });
  }

  async function wait(time) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time * 1000);
    });
  }
  async function getOrganisation() {
    try {
      const url = `${getUrl("organisations")}/query`;
      setLoadingOrgCode(true);
      //await wait(1);
      const response = await requests.getWithAuth(url, { orgCode });
      setOrganisation(response.data);
      setLoadingOrgCode(false);
    } catch (e) {
      setLoadingOrgCode(false);
      helpers.displayMessage(e);
    }
  }
  async function getOrgByQueryID(organisationID) {
    try {
      const url = `${getUrl("organisations")}/query`;
      setLoadingOrgByID(true);
      //await wait(1);
      const response = await requests.getWithAuth(url, { _id: organisationID });
      setOrganisationDataByID(response.data);
      setLoadingOrgByID(false);
    } catch (e) {
      setLoadingOrgByID(false);
      helpers.displayMessage(e);
    }
  }

  function contractSuccess() {
    Modal.success({
      content: "Your contract was created and sent...",
    });
  }
  function clearData() {
    setFormValues({
      amount: "",
      description: "",
      contactEmail: "",
      startDate: "",
      endDate: "",
    });
    if (organisationDataByID._id) {
      clearOrganisationID();
      setOrganisationDataByID({});
    }
    if (organisation._id) {
      setOrganisation({});
    }
  }

  async function handleFormSubmit() {
    try {
      const url = `${getUrl("contracts")}`;
      setLoadingSubmit(true);
      const data = {
        ...formValues,
        organisation: organisation._id || organisationDataByID._id,
        organisationName: organisation.name || organisationDataByID.name,
      };
      const response = await requests.postWithAuth(url, data);
      if (response) {
        // setOrganisation(response.data);
        setLoadingSubmit(false);
        contractSuccess();
        clearData();
      }
    } catch (e) {
      setLoadingSubmit(false);
      helpers.displayMessage(e);
    }
  }

  return (
    <div className="cnt-container">
      <SearchOrg
        searchBy={searchBy}
        handleChange={handleChange}
        handleSearch={getOrganisation}
        loading={loadingOrgCode || loadingOrgByID}
        handleSearchInputChange={handleSearchInputChange}
        searchValue={orgCode}
      />

      {organisation?._id || organisationDataByID?._id ? (
        <CreateContractForm
          handleInputChange={handleInputChange}
          organisation={organisation}
          organisationDataByID={organisationDataByID}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
          handleSubmit={handleFormSubmit}
          loading={loadingSubmit}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    organisationID: state.organisationsReducer.organisationID,
  };
};

export default connect(mapStateToProps, {
  clearOrganisationID,
})(Contracts);
