import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Spin, PageHeader } from "antd";

import { useHistory, useParams } from "react-router-dom";
import { Table } from "../../components";

import { getUrl, requests } from "../../globals/requests";

import { Container, TableContainer } from "../../globals/styles";
import { helpers, dateUtil } from "../../utils";
import moment from "moment";

function Organisations() {
  const [contracts, setContracts] = useState([]);
  const [, setSelected] = useState({});
  const [loading, setLoading] = useState(true);

  let { organisationId } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function getContracts() {
      try {
        setLoading(true);
        const url = `${getUrl("contracts")}/organisations/${organisationId}`;
        const response = await requests.getWithAuth(url);
        const data = response.data;
        const contracts = [];
        setLoading(false);

        data.forEach((eachContract) => {
          const { amount, currency, startDate, endDate, created_at } =
            eachContract;
          const today = +new Date();
          let status = "";
          if (+new Date(startDate) > today) {
            status = "Future";
          } else if (today > +new Date(endDate)) {
            status = "Past";
          } else {
            status = "Current";
          }

          contracts.push({
            ...eachContract,
            status,
            amount: helpers.toCurrency(amount, currency),
            // startDate: dateUtil.formatDate(startDate),
            startDate: startDate.slice(0, 10),
            // endDate: dateUtil.formatDate(endDate),
            endDate: endDate?.slice(0, 10),
            // created_at: dateUtil.formatDate(created_at),
            created_at: created_at.slice(0, 10),
          });
        });
        setContracts(contracts.reverse());
      } catch (e) {
        console.log(e);
        setLoading(false);

        helpers.displayMessage(e);
      }
    }

    getContracts();
  }, []);

  function viewContract(contract) {
    history.push(`/dashboard/contracts/${contract._id}`);
  }

  async function deleteContract(id) {
    try {
      const url = `${getUrl("contracts")}/${id}`;
      await requests.deleteWithAuth(url);
      setContracts(contracts.filter((el) => el._id !== id));
    } catch (e) {
      helpers.displayMessage(e);
    }
  }

  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created On",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Paid",
      dataIndex: "paid",
      key: "paid",
      render: (paid) => {
        const styleObj = {
          marginTop: "12px",
        };
        paid ? (styleObj.color = "green") : (styleObj.color = "red");

        return <p style={styleObj}>{paid ? "Yes" : "No"}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const styleObj = {
          marginTop: "12px",
        };
        switch (status) {
          case "Active":
            styleObj.color = "green";
            break;
          case "Expired":
            styleObj.color = "red";
            break;
          default:
            styleObj.color = "black";
        }
        return <p style={styleObj}>{status}</p>;
      },
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "x",
      render: (contract) => (
        <button onClick={() => viewContract(contract)}>Edit</button>
      ),
    },
    {
      title: "Delete",
      dataIndex: "",
      key: "y",
      render: (contract) => (
        <button
          style={{ background: "red", border: "none", color: "white" }}
          onClick={() => deleteContract(contract._id)}
        >
          Delete
        </button>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="spin-contain">
        <Spin delay={100} size="large" tip="Loading Organisations...">
          {/* <Alert
      message="Alert message title"
      description="Further details about the context of this alert."
      type="info"
    /> */}
        </Spin>
      </div>
    );
  }

  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={contracts[0]?.organisation?.name}
        subTitle="Contracts"
      />

      <TableContainer>
        <Table columns={columns} dataSource={contracts} />
      </TableContainer>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedOrganisation: state.organisationsReducer.selectedOrganisation,
  };
};

export default connect(mapStateToProps)(Organisations);
