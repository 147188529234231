import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";

import { Select, Switch, Checkbox, PageHeader, Spin, Alert } from "antd";

import { getUrl, requests } from "../../globals/requests";
import { convertToEndOfDayISO } from "../../utils/date";
import {
  Container,
  SpaceBetween,
  CenterHorizontal,
} from "../../globals/styles";
import { DatePicker, Input, Button } from "../../components";
import { helpers } from "../../utils";
import "./editcontract.css";

const { Option } = Select;

function EditContracts(props) {
  const [contract, setContract] = useState({});
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  let { contractId } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function getContract() {
      try {
        setLoading(true);
        setErrMsg("");
        const url = `${getUrl("contracts")}/${contractId}`;
        const response = await requests.getWithAuth(url);

        setContract(response.data);
        setLoading(false);
      } catch (e) {
        helpers.displayMessage(e);
        setErrMsg(e.message);
        setLoading(false);
      }
    }

    getContract();
  }, [contractId]);

  // const plainOptions = ["Apple", "Pear", "Orange"];
  const options = [
    { label: "Broadcast Messaging", value: "allow_broadcast_message" },
    { label: "View Read Broadcasts", value: "allow_boradcast_read_receipts" },
  ];
  // const optionsWithDisabled = [
  //   { label: "Apple", value: "Apple" },
  //   { label: "Pear", value: "Pear" },
  //   { label: "Orange", value: "Orange", disabled: false },
  // ];

  function onStartDateChange(date, dateString) {
    setContract({ ...contract, startDate: dateString });
  }

  function onEndDateChange(date, dateString) {
    const correctEndOfday = convertToEndOfDayISO(dateString);
    setContract({ ...contract, endDate: correctEndOfday });
  }

  function onChange(key, value) {
    console.log({ [key]: value });
    setContract({ ...contract, [key]: value });
  }

  function handleInputChange(e) {
    const { name, value } = e.target;

    setContract({ ...contract, [name]: value });
  }

  async function handleContractUpdate() {
    try {
      const url = `${getUrl("contracts")}/${contract._id}`;
      console.log(contract);
      setLoading(true);

      const response = await requests.putWithAuth(url, contract);

      setLoading(false);
      setContract(response.data);
      helpers.successModal("Contract Updated!!!");
    } catch (e) {
      setLoading(false);

      helpers.displayMessage(e);
    }
  }

  function renderFeatures() {
    return (
      <>
        <Checkbox.Group
          options={options}
          defaultValue={["allow_broadcast_message"]}
          onChange={onChange}
        />
        {/* <Checkbox.Group
          options={optionsWithDisabled}
          disabled
          defaultValue={["Apple"]}
          onChange={onChange}
        /> */}
      </>
    );
  }

  if (errMsg !== "") {
    return (
      <Alert
        message="Error occured"
        description="Further details about the context of this alert."
        type="error"
      />
    );
  }

  if (!contract._id) return null;

  // if (loading) {
  //   return (
  //     <div className="spin-contain">
  //       <Spin delay={100} size="large" tip="Loading Organisations...">
  //         {/* <Alert
  //     message="Alert message title"
  //     description="Further details about the context of this alert."
  //     type="info"
  //   /> */}
  //       </Spin>
  //     </div>
  //   );
  // }

  return (
    <Container>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={contract?.organisation?.name}
        subTitle="Contract"
      />
      <SpaceBetween>
        <div>
          <p>
            <b>Contact Name</b>
          </p>
          {/* <p style={{ textTransform: "capitalize" }}>{contract.contactName}</p> */}
          <Input
            name="contactName"
            onChange={handleInputChange}
            value={contract.contactName}
          />
        </div>
        <div>
          <p className="edctrct-align-right">
            <b>Contact Email</b>
          </p>
          {/* <p>{contract.contactEmail}</p> */}
          <Input
            name="contactEmail"
            onChange={handleInputChange}
            value={contract.contactEmail}
          />
        </div>
      </SpaceBetween>
      <SpaceBetween>
        <div>
          <p>
            <b>Start Date</b>
          </p>
          <DatePicker
            defaultValue={moment(contract.startDate)}
            onChange={onStartDateChange}
          />
        </div>
        <div>
          <p className="edctrct-align-right">
            <b>End Date</b>
          </p>
          <DatePicker
            defaultValue={moment(contract.endDate, "YYYY/MM/DD")}
            onChange={onEndDateChange}
          />
        </div>
      </SpaceBetween>
      <SpaceBetween>
        <div>
          <p>
            <b>Amount</b>
          </p>
          <Input
            disabled={contract?.paid}
            inputType="number"
            onChange={(val) => onChange("amount", val)}
            value={contract.amount}
          />
        </div>
        <div>
          <p className="edctrct-align-right">
            <b>Currency</b>
          </p>
          <Select
            defaultValue={contract.currency}
            style={{ width: 120 }}
            disabled
            onChange={(val) => onChange("currency", val)}
          >
            <Option value="USD">USD</Option>
            {/* <Option value="EUR">EUR</Option>
            <Option value="GBP">GBP</Option> */}
          </Select>
        </div>
      </SpaceBetween>

      <SpaceBetween>
        <div>
          <b>Description</b>
        </div>
        <p>{contract.description}</p>
      </SpaceBetween>
      {/* <SpaceBetween>
        <div>
          <b>Features</b>
          <p>{renderFeatures()}</p>
        </div>
      </SpaceBetween> */}
      {/* <SpaceBetween>
        <div>
          <p>
            <b>Active</b>
          </p>
        </div>
        <Switch
          defaultChecked={contract.active}
          onChange={(val) => onChange("active", val)}
        />
      </SpaceBetween> */}
      <SpaceBetween>
        <div>
          <p>
            <b>Paid</b>
          </p>
        </div>
        <Switch
          defaultChecked={contract.paid}
          onChange={(val) => onChange("paid", val)}
        />
      </SpaceBetween>
      <CenterHorizontal>
        <Button
          onClick={handleContractUpdate}
          loading={loading}
          title="Update Contract"
          type="primary"
        />
      </CenterHorizontal>
    </Container>
  );
}

export default EditContracts;
