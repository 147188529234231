import React from "react";
import { Input, InputNumber } from "antd";

function MyInput(props) {
  const { inputType } = props;
  if (inputType === "number") return <InputNumber {...props} />;
  return <Input {...props} />;
}

export default MyInput;
