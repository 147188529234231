import React from "react";
import { Spin } from "antd";
import { Loader, SpinContainer } from "../../styles";

function ViewLoader(props) {
  if (props.show) {
    return (
      <Loader>
        <SpinContainer>
          <Spin
            delay={100}
            size="large"
            tip={props.loadingText || "Loading..."}
          ></Spin>
        </SpinContainer>
      </Loader>
    );
  }

  return null;
}

export default ViewLoader;
