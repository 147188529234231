import styled from "styled-components";
export const SortingOptionWrraper = styled.div`
  background-color: var(--white);
  padding: var(---verticalMargin);
  display: flex;
  //   flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--verticalMargin);
  position: relative;
  padding: 4px 16px;
  font-size: 1em;
  color: var(--customiseFontColor);
  & > span {
    margin: 5px;
  }
`;
export const SortingOptionContent = styled.div`
  background-color: var(--blue);
  color: var(--white);
  padding: var(---verticalMargin);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--verticalMargin);
  position: relative;
  width: 150px;
  height: 32px;
  border: 1px solid var(--blue);
  border-radius: 32px;
  padding: 4px 16px;
  font-size: 14px;
  :hover {
    cursor: pointer;
    box-shadow: var(--shadow);
  }
`;

export const OptionWraper = styled.div`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 38px;
  right: 15px;
  width: 150px;
  transition: 0.3s;
  height: ${({ isOpen }) => (isOpen ? "128px" : "0px")};
  z-index: ${({ isOpen }) => (isOpen ? "2" : "-1")};
  box-shadow: var(--shadow);
  user-select: none;
  & > ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: ${({ isOpen }) => (isOpen ? "128px" : "0px")};
    margin: 0;
    padding: 0;
  }
  & > ul > li {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px;
    cursor: pointer;
    transition: 0.3s;
  }
  & > ul > li:hover {
    box-shadow: var(--shadow);
  }
`;
export const DownOutlinedStyleClosed = {
  position: "absolute",
  top: "10px",
  right: "10px",
  transition: "0.3s",
  transform: "rotate(0)",
};
export const DownOutlinedStyleOpen = {
  position: "absolute",
  top: "10px",
  right: "10px",
  transition: "0.3s",
  transform: "rotate(180deg)",
};
