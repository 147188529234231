import Login from "../views/Login/Login";
import Dashboard from "../layouts/dashboard/Dashboard";
import Landing from "../views/Landing/Landing";
// import Query from '../Views/Query/Query';
// import Login from "../Views/Login/Login";
// import asyncComponent from "../Components/AsyncComponent";
// const AsyncDashboardLayout = asyncComponent(() =>
//   import("../Layouts/Dashboard/DashboardLayout.js")
// );

var indexRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    exact: false,
    protectedRoute: true,
  },
  { path: "/login", name: "Login", component: Login, exact: true },
  { path: "/", name: "Landing", component: Landing, exact: true },
];

export default indexRoutes;
