import styled, { css } from "styled-components";

const Container = styled.div`
  background-color: var(--white);
  padding: var(---verticalMargin);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 650px; */
  /* height: 450px; */
  padding: var(--verticalMargin);
  position: relative;
`;

const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: var(--verticalMargin) 0;
  /* width: 800px;
  height: 500px; */
`;

const FlexEnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* width: 800px;
  height: 500px; */
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 150px;
`;

const SwitchContainer = styled.div`
  margin: 20px 0;
  width: 150px;
  display: flex;
  justify-content: space-between;
`;

const CardHeader = styled.div`
  font-size: 20px;
`;

const TableContainer = styled.div`
  margin-top: 20px;
`;

const TableButtonsContainer = styled.div`
  height: 35px;
`;

const CenterHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${(props) =>
    props.margin &&
    css`
      margin: 15px 0;
    `}
`;

const Loader = styled.div`
  position: absolute;
  background-color: rgba(1, 1, 1, 0);
  width: 100%;
  height: 100%;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpinContainer = styled.div`
  background-color: white;
  width: 300px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
`;

export {
  Container,
  SpaceBetween,
  ButtonContainer,
  SwitchContainer,
  CardHeader,
  FlexEnd,
  TableContainer,
  TableButtonsContainer,
  CenterHorizontal,
  Loader,
  SpinContainer,
};
