// import asyncComponent from '../Components/AsyncComponent';
import Contracts from "../views/contracts/Contracts";
import Overview from "../views/Overview/Overview";
import Organisations from "../views/Organisations/Organisations";
import OrgContracts from "../views/OrgContracts/OrgContracts";
import EditContracts from "../views/EditContracts/EditContracts";
import Backups from "../views/Backups/Backups";

// //async
// const asyncUsers = asyncComponent(()=> {import('../Views/Users/Users')});
// const asyncShippers= asyncComponent(()=> {import('../Views/Shippers/Shippers')});
// const asyncRecords = asyncComponent(()=> {import('../Views/Records/Records')});
// const asyncFinancials = asyncComponent(()=> {import('../Views/Financials/Financials')});

// const asyncDiscount = asyncComponent(()=> {import('../Views/Discount/Discount')})
// const asyncPricing= asyncComponent(()=> {import('../Views/Pricing/Pricing')});
// const asyncSettings = asyncComponent(()=> {import('../Views/Settings/Settings')});
// const asyncQuery = asyncComponent(()=> {import('../Views/Query/Query')});

// const asyncZones = asyncComponent(()=> {import('../Views/Zones/Zones')})
// const asyncViewZone = asyncComponent(()=> {import('../Views/Zones/ViewZone')});
// const asyncOperations = asyncComponent(()=> {import('../Views/Operations/Operations')});
// const asyncViewOperation= asyncComponent(()=> {import('../Views/Operations/ViewOperation')});
// const asyncHome = asyncComponent(()=> {import('../Views/Home/Home')});

//end
const dashboardRoutes = [
  {
    path: "/dashboard/contracts",
    name: "Contract Management",
    icon: "pe-7s-graph",
    exact: true,
    component: Contracts,
    protectedRoute: true,
  },
  {
    path: "/dashboard",
    name: "Overview",
    exact: true,
    component: Overview,
    protectedRoute: true,
  },
  {
    path: "/dashboard/organisations",
    name: "Organisations",
    exact: true,
    component: Organisations,
    protectedRoute: true,
  },

  {
    path: "/dashboard/orgContracts/:organisationId",
    name: "Contracts",
    exact: true,
    component: OrgContracts,
    protectedRoute: true,
  },
  {
    path: "/dashboard/contracts/:contractId",
    name: "Edit Contracts",
    exact: true,
    component: EditContracts,
    protectedRoute: true,
  },
  {
    path: "/dashboard/backups",
    name: "Backups",
    exact: true,
    component: Backups,
    protectedRoute: false,
  },
  // { redirect: true, path: "/", to: "/dashboard", name: "Dashboard" }
];

export default dashboardRoutes;
