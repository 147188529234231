import {
  SET_ORGANISATION,
  SET_ORGANISATIONS,
  SET_SELECTED_ORGANISATIONS,
  SET_ORGANISATION_ID,
  CLEAR_ORGANISATION_ID,
  SET_ORGANISATION_SORT_TYPE,
  SET_ORGANISATION_SORT_TYPE_ACTIVE,
  SET_ORGANISATION_SORT_TYPE_DISACTIVE,
  SET_ORGANISATIONS_AFTER_SORT,
} from "../types";

const INITIAL_STATE = {
  organisation: {},
  organisations: [],
  selectedOrganisation: {},
  loadingGetOrgs: false,
  loadingGenerateOrgCode: false,
  organisationID: localStorage.getItem("orgID")
    ? localStorage.getItem("orgID")
    : "",
  sortOrganisationType: [
    { item: "Name A-Z", active: true },
    { item: "Name Z-A", active: false },
    { item: "End Date Paid", active: false },
    { item: "End Date Ever", active: false },
  ],
  sortOrganisationTypeActive: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ORGANISATION:
      return { ...state, organisation: action.payload };

    case SET_ORGANISATIONS:
      return { ...state, organisations: action.payload };

    case SET_SELECTED_ORGANISATIONS:
      return { ...state, selectedOrganisation: action.payload };

    case SET_ORGANISATION_ID:
      localStorage.setItem("orgID", action.payload);
      return { ...state, organisationID: action.payload };

    case CLEAR_ORGANISATION_ID:
      localStorage.setItem("orgID", "");
      return { ...state, organisationID: "" };

    case SET_ORGANISATION_SORT_TYPE:
      return {
        ...state,
        sortOrganisationType: state.sortOrganisationType.map((option) => {
          if (option.item === action.payload.item) {
            return { item: option.item, active: true };
          } else {
            return { item: option.item, active: false };
          }
        }),
      };
    case SET_ORGANISATION_SORT_TYPE_ACTIVE:
      return { ...state, sortOrganisationTypeActive: true };

    case SET_ORGANISATION_SORT_TYPE_DISACTIVE:
      return { ...state, sortOrganisationTypeActive: false };

    case SET_ORGANISATIONS_AFTER_SORT:
      return { ...state, organisations: action.payload };

    default:
      return state;
  }
};

export default userReducer;
