import React, { useEffect } from "react";

import { connect } from "react-redux";
import {
  setOrganisationSortType,
  setOrganisationSortTypeActive,
  setOrganisationSortTypeDisactive,
} from "../../state/actions/organisationActions";

import { DownOutlined, CaretDownOutlined } from "@ant-design/icons";
import {
  SortingOptionWrraper,
  SortingOptionContent,
  DownOutlinedStyleClosed,
  DownOutlinedStyleOpen,
  OptionWraper,
} from "./SortingBtn.styles";

const ShowSortOpt = ({
  sortOrganisationType,
  sortOrganisationTypeActive,
  setOrganisationSortTypeActive,
  setOrganisationSortTypeDisactive,
  setOrganisationSortType,
}) => {
  const refs = React.createRef();

  const handleClickOutside = (e) => {
    if (sortOrganisationTypeActive) {
      if (refs.current.contains(e.target)) {
        return document.removeEventListener("click", handleClickOutside);
      } else {
        setOrganisationSortTypeDisactive();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  });

  const options = sortOrganisationType.map((option, index) => {
    return (
      <li
        key={index}
        onClick={() => {
          setOrganisationSortType(option);
          sortOrganisationTypeActive && setOrganisationSortTypeDisactive();
          !sortOrganisationTypeActive && setOrganisationSortTypeActive();
        }}
      >
        {option.item}
      </li>
    );
  });
  return (
    <OptionWraper isOpen={sortOrganisationTypeActive} ref={refs}>
      <ul>{options}</ul>
    </OptionWraper>
  );
};

const SortingBtn = ({
  sortOrganisationType,
  setOrganisationSortType,
  sortOrganisationTypeActive,
  setOrganisationSortTypeActive,
  setOrganisationSortTypeDisactive,
}) => {
  const handleClickOption = () => {
    if (sortOrganisationTypeActive) {
      setOrganisationSortTypeDisactive();
    } else {
      setOrganisationSortTypeActive();
    }
  };

  const handleActiveSortOtion =
    sortOrganisationType?.length &&
    sortOrganisationType.filter((item) => item.active);

  return (
    <SortingOptionWrraper>
      <span>SORT BY:</span>
      <SortingOptionContent onClick={handleClickOption}>
        {handleActiveSortOtion[0].item}
        {/* <DownOutlined
          style={isOpen ? DownOutlinedStyleOpen : DownOutlinedStyleClosed}
        /> */}
        <CaretDownOutlined
          style={
            sortOrganisationTypeActive
              ? DownOutlinedStyleOpen
              : DownOutlinedStyleClosed
          }
        />
      </SortingOptionContent>
      <ShowSortOpt
        sortOrganisationType={sortOrganisationType}
        sortOrganisationTypeActive={sortOrganisationTypeActive}
        setOrganisationSortTypeActive={setOrganisationSortTypeActive}
        setOrganisationSortType={setOrganisationSortType}
        setOrganisationSortTypeDisactive={setOrganisationSortTypeDisactive}
      />
    </SortingOptionWrraper>
  );
};

const mapStateToProps = (state) => {
  return {
    sortOrganisationType: state.organisationsReducer.sortOrganisationType,
    sortOrganisationTypeActive:
      state.organisationsReducer.sortOrganisationTypeActive,
  };
};

export default connect(mapStateToProps, {
  setOrganisationSortType,
  setOrganisationSortTypeActive,
  setOrganisationSortTypeDisactive,
})(SortingBtn);
