import React from "react";
import Block from "./Block";

import { BoxContainer } from "../styles";

function Blocks(props) {
  const { summary } = props;
  return (
    <BoxContainer>
      <Block value={summary.noOfTrips ?? 0} display="Trips" />
      <Block value={summary.noOfParticipants ?? 0} display="Participants" />
      <Block value={summary.noOfStaffs ?? 0} display="Teachers" />
      <Block value={summary.noOfStudents ?? 0} display="Students" />
      {/* <Block summary={summary} display="Contracts" /> */}

      {/* <Block value={10} display="Trips" />
      <Block value={7} display="Participants" />
      <Block value={10} display="Staffs" />
      <Block value={10} display="Students" /> */}
    </BoxContainer>
  );
}

export default Blocks;
