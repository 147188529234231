// import { getUrl, requests } from "../../globals/requests";
import {
  SET_ORGANISATION,
  SET_ORGANISATIONS,
  LOADING_GET_ORGANISATIONS,
  SET_ORGANISATION_ID,
  CLEAR_ORGANISATION_ID,
  SET_ORGANISATION_SORT_TYPE,
  SET_ORGANISATION_SORT_TYPE_ACTIVE,
  SET_ORGANISATION_SORT_TYPE_DISACTIVE,
  SET_ORGANISATIONS_AFTER_SORT,
} from "../types";
import { requests, getUrl } from "../../globals/requests";

export const setOrganisation = (org) => {
  return {
    type: SET_ORGANISATION,
    payload: org,
  };
};

export const getUserOrganisation = (userId) => {
  return async (dispatch) => {
    try {
      const url = `${getUrl("organisations")}/query`;
      const query = {
        "admins.userId": userId,
      };
      const response = await requests.getWithAuth(url, query);

      dispatch({
        type: SET_ORGANISATION,
        payload: response.data,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  };
};

export const getFlattenedOrganisations = () => {
  return async (dispatch) => {
    try {
      // const url = `${getUrl("organisations")}/queryAll/flatten`;
      const url = `${getUrl("organisations")}/queryAll/flattenWithContracts`;

      dispatch({
        type: LOADING_GET_ORGANISATIONS,
        payload: true,
      });

      const response = await requests.getWithAuth(url);

      dispatch({
        type: LOADING_GET_ORGANISATIONS,
        payload: false,
      });

      dispatch({
        type: SET_ORGANISATIONS,
        payload: response.data,
      });

      return response.data;
    } catch (e) {
      dispatch({
        type: LOADING_GET_ORGANISATIONS,
        payload: false,
      });
      throw e;
    }
  };
};
export const setOrganisationID = (org) => {
  return {
    type: SET_ORGANISATION_ID,
    payload: org,
  };
};

export const clearOrganisationID = () => {
  return {
    type: CLEAR_ORGANISATION_ID,
  };
};

export const setOrganisationSortType = (org) => {
  return {
    type: SET_ORGANISATION_SORT_TYPE,
    payload: org,
  };
};

export const setOrganisationSortTypeActive = () => {
  return {
    type: SET_ORGANISATION_SORT_TYPE_ACTIVE,
  };
};

export const setOrganisationSortTypeDisactive = () => {
  return {
    type: SET_ORGANISATION_SORT_TYPE_DISACTIVE,
  };
};

export const setOrganisationsAfterSort = (org) => {
  return {
    type: SET_ORGANISATIONS_AFTER_SORT,
    payload: org,
  };
};
