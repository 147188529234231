// User types
export const SET_USER = "SET_USER";
// Contract types
export const SET_LATEST_CONTRACT = "SET_LATEST_CONTRACT";
// Organisation types
export const SET_ORGANISATION = "SET_ORGANISATION";
export const SET_ORGANISATIONS = "SET_ORGANISATIONS";
export const SET_SELECTED_ORGANISATIONS = "SET_SELECTED_ORGANISATIONS";
export const LOADING_GET_ORGANISATIONS = "LOADING_GET_ORGANISATIONS";
export const SET_ORGANISATION_ID = "SET_ORGANISATION_ID";
export const CLEAR_ORGANISATION_ID = "CLEAR_ORGANISATION_ID";
export const SET_ORGANISATION_SORT_TYPE = "SET_ORGANISATION_SORT_TYPE";
export const SET_ORGANISATION_SORT_TYPE_ACTIVE =
  "SET_ORGANISATION_SORT_TYPE_ACTIVE";
export const SET_ORGANISATION_SORT_TYPE_DISACTIVE =
  "SET_ORGANISATION_SORT_TYPE_DISACTIVE";
export const SET_ORGANISATIONS_AFTER_SORT = "SET_ORGANISATIONS_AFTER_SORT";
